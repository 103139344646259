// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import questionImage from './assets/question.webp';
import heartImage from './assets/heart.webp';

function App() {
    const [noCount, setNoCount] = useState(0);
    const [yesPressed, setYesPressed] = useState(false);

    const noMessages = [
        "No",
        "Are you sure?",
        "Really sure?",
        "Think again please?",
        "Last chance!",
        "Surely not?",
        "You might regret this!",
        "Give it another thought!",
        "Are you absolutely certain?",
        "This is making me sad :(",
        "I'm gonna cry...",
        "You're breaking my heart ;(",
    ];

    const getNoButtonText = () => {
        return noMessages[Math.min(noCount, noMessages.length - 1)];
    };

    const getYesButtonStyle = () => {
        if (noCount === 0) return {};
        return {
            fontSize: `${Math.min(noCount * 20 + 16, 200)}px`,
            padding: `${Math.min(noCount * 10 + 16, 200)}px`,
            position: noCount > 5 ? 'fixed' : 'static',
            top: '50%',
            left: '50%',
            transform: noCount > 5 ? 'translate(-50%, -50%)' : 'none',
            zIndex: 999
        };
    };

    const handleYesClick = () => {
        setYesPressed(true);
    };

    const handleNoClick = () => {
        setNoCount(prevCount => prevCount + 1);
    };

    useEffect(() => {
        document.title = yesPressed ? "Yay! 🎉" : "Will you?";
    }, [yesPressed]);

    useEffect(() => {
        if (!yesPressed) {
            document.title = noCount === 0 ? "Will you?" : getNoButtonText();
        }
    }, [noCount, yesPressed]);

    return (
        <div className="App">
            <div className={`question-container ${yesPressed ? 'hidden' : ''}`}>
                <img
                    src={questionImage}
                    alt="Question"
                    className="question-image"
                />
                <h1>Will you be my valentine?</h1>
                <div className="buttons-container">
                    <button
                        className="yes-button"
                        style={getYesButtonStyle()}
                        onClick={handleYesClick}
                    >
                        Yes
                    </button>
                    <button
                        className="no-button"
                        onClick={handleNoClick}
                    >
                        {getNoButtonText()}
                    </button>
                </div>
            </div>

            <div className={`success-message ${yesPressed ? 'visible' : ''}`}>
                <img
                    src={heartImage}
                    alt="Heart"
                    className="heart-image"
                />
                <h1>Yay! 🎉</h1>
            </div>
        </div>
    );
}

export default App;